import * as React from "react"

const Logo = () => {
  const styles = {"width":"192\npx","imageRendering":"pixelated","MsInterpolationMode":"nearest-neighbor"}
  return (
    <img style={styles} src="/redairship-007-0001.gif" />
  )
}

export default Logo
